
.card_content {
  padding: 17px 15px 15px;
}
.bottom_border {
  border-bottom: 1px solid var(--bluegrey-500);
}
.card_height {
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 0;
  position: relative;
  overflow: hidden;
}
/* .card_inner_content {
  min-height: 400px;
  background: var(--main-thm-bg);  
} */
/* .card_left_content {
  min-height: 110px;
  border-right: 1px solid var(--bluegrey-500);
} */
/* .card_right_content {
  background: var(--mainbg-gray);
  min-height: 110px;
  border-right: 1px solid var(--bluegrey-500);
} */

.card_top_bg .MuiGrid-container,
.card_top_bg .container {
  align-items: center;
}
/* .small_heading {
  text-align: left;
  letter-spacing: 0.6px;
  color: var(--text-color);
  font-size: calc(13px + (15 - 14) * ((100vw - 300px) / (1920 - 300))) !important;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 33px;
  display: flex;
  align-items: center;
}
.small_heading svg,
.small_heading img {
  margin-right: 2px;
   width: 23px; 
  height: auto;
  color: var(--thm-bg);
  margin-top: -3px;
} */
.img_icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.top_right_content {
  text-align: right;
  align-items: center;
  display: inherit;
  justify-content: flex-end;
}
/* .listing_item {
  padding: 10px;
  background: var(--color-white);
  height: 48px;
  border-bottom: 1px solid var(--bluegrey-500);
  display: flex;
  align-items: center;
  justify-content: center;
} */
.selcted_item {
  position: relative;
}
.selcted_item::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  border: 2.8px solid var(--thm-bg);
  z-index: 0;
  background: var(--thm-bg);
  height: 100%;
}
.no-vendor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*Vendor List Showing Custom Table CSS :: START*/
.select_vendor_list_table tr td .click_to_add_btn span
{
  text-transform: capitalize;
font-size: calc(13px + (14 - 12) * ((100vw - 300px) / (1920 - 300))) !important;
font-weight: 500 !important;
}
.select_vendor_list_table .MuiTableCell-stickyHeader p {
  color: var(--text-primary);
  text-align: left;
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 500;  
  text-transform: uppercase;
  padding: 5px 10px;  
}
/* .select_vendor_list_table .MuiTableCell-stickyHeader button svg path {
  background: var(--thm-white);
  color:var(--bluegrey-500);
}
.select_vendor_list_table .MuiTableCell-stickyHeader button.Mui-selected svg path
  {
    color:var(--thm-bg);
  } */
.select_vendor_list_table .MuiTableCell-stickyHeader,
.select_vendor_list_table .MuiTableHead-root {
  background: var(--mainbg-gray);
  border-top: 1px solid var(--bluegrey-500);
  border-bottom: 1px solid var(--bluegrey-500);
}
.select_vendor_list_table .MuiTableHead-root tr {
  white-space: nowrap;
}
.select_vendor_list_table .MuiTableHead-root tr th {
  padding: 5px 0;
}
.select_vendor_list_table .MuiTableBody-root tr td .MuiFormControl-root {
  vertical-align: middle !important;
}
.select_vendor_list_table .MuiTableBody-root {
  background: var(--thm-white);
  position: relative;
}
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(2),
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3),
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(1),
.select_vendor_list_table .template_table_first_row,
.select_vendor_list_table .template_table_second_row,
.select_vendor_list_table .template_table_third_row {
  max-width: 400px !important;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--thm-white);
  z-index: 888;
}

.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(2),
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3),
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(1) {
  background-color: var(--mainbg-gray);
  z-index: 889;
}
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(2),
.select_vendor_list_table .template_table_third_row {
  left: 65px;
  min-width: 300px;
}
.select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3),
.select_vendor_list_table .template_table_second_row {
  left: 350px;
  min-width: 200px;
}

.select_vendor_list_table .MuiTableBody-root tr td:nth-of-type(1) p {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  min-width: 380px;
  text-transform: uppercase;
}
.select_vendor_list_table .MuiTableBody-root tr td:nth-of-type(3) p {
  color: var(--thm-bg);  
  font-weight: 500;
  text-transform: uppercase;
}
.select_vendor_list_table .MuiTableBody-root tr td {
  white-space: nowrap;
  padding: 5px 10px;
}
/* .select_vendor_list_table .MuiTableBody-root tr td p {
  text-align: left;
  font-size: calc(13.5px + (15 - 14) * ((100vw - 300px) / (1920 - 300)));
  letter-spacing: 0.7px;
  color: var(--text-color);  
  font-weight: 500;
  text-transform: capitalize;
} */


.vendor_not_available_main span {
  font-size: calc(14px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
  line-height: 17px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  color: var(--text-color);
  margin-bottom: 10px;
}
.vendor_not_available_main span span {
  color: var(--thm-bg);
  font-weight: 500;
  cursor: pointer;
}

.vendor_not_available_main button {
  background: var(--thm-bg);
  border-radius: 3px;
  min-height: 25px;
}
.vendor_not_available_main button span {
  color: var(--thm-white);
  font-size: 14px;
  margin: 0;
  padding: 5px 10px;
}
.vendor_not_available_main button:hover {
  background: var(--thm-bg);
}
.vendor_not_available_main button:hover span {
  color: var(--thm-white);
}
.template_textbox input {
  height: 25px !important;    
}
.template_textbox.local_template_textfield .MuiOutlinedInput-root fieldset
{
  border-color: var(--bluegrey-500) !important
}
.template_textbox.local_template_textfield .MuiOutlinedInput-root.Mui-focused fieldset
{
  border-color: var(--thm-bg) !important;
}
.template_textbox.local_template_textfield .MuiOutlinedInput-root.Mui-error fieldset
{
  border-color: #CCC !important;
}

.template_Date_picker.template_textbox input
{
  height: 7px !important;
}

/* .template_textbox .Mui-focused fieldset {
  border-color:var(--thm-bg) !important;
} */
.template_dropdown .MuiSelect-selectMenu {
  min-height: 15px !important;
}
.select_vendor_list_table .template_dropdown .MuiSelect-selectMenu {
  max-height: 3px !important;
}
/* .update_in_master_btn {
  padding: 10px 10px !important;
  height: 40px;
} */
/* .template_popup button,
.template_table_button {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--thm-white);
  text-transform: uppercase;
  background-color: var(--thm-bg) !important;
  width: 90%;
  text-align: center;
}
.template_table_button {
  width: 60%;
} */
.deliveryschedule_btn {
  padding: 0;
}
.deliveryschedule_btn svg.MuiSvgIcon-root {
  color: var(--outline-color) !important;
  font-size: calc(16px + (15 - 13) * ((100vw - 300px) / (1920 - 300))) !important;
  margin-right: 10px;
}
.template_popup button.close_btn span {
  color: var(--thm-bg) !important;
}
.template_popup button.close_btn {
  background-color: transparent !important;
}
.template_popup .MuiMenu-paper {
  background: var(--thm-white);
}
.template_popup textarea {
  background: var(--thm-white);
  border: 1px solid var(--bluegrey-500);
  border-radius: 3px;
  min-height: 60px;
}

.template_popup fieldset {
  display: none !important;
}
.template_popup .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-width: 0 !important;
  border-style: none !important;
  border-color: transparent !important;
}
.template-card .template_dropdown .MuiOutlinedInput-input {
  min-height: 4px !important;
}
.template-card .template_Date_picker {
  width: 100%;
}
.template_rfqstatic .local_recepients_card .MuiCardContent-root,
.template_international_recipients .local_recepients_card .MuiCardContent-root,
.template_local_recipients .local_recepients_card .MuiCardContent-root {
  padding: 25px 0 !important;
}
.template-card .template_Date_picker input {
  height: 10px;
}
.MuiButtonGroup-root.MuiButtonGroup-outlined:hover,
.MuiButtonGroup-root.MuiButtonGroup-outlined:hover button
{
  border: none !important;
}
/*Vendor List Showing Custom Table CSS :: END*/

@media only screen and (max-width: 991.5px) {
  .page_content_main
  {
      max-width: 95vw;
  }
  .card_top_bg .MuiGrid-container,
  .card_top_bg .container {
      align-items: center;
      /* display: contents; */
  }
  .card_height {
      min-height: 100%;
      max-height: 100%;
  }
  .search_link_vendor,
  .template_dropdown_main {
      margin-top: 10px !important;
  }
  .template_dropdown_menu {
      min-width: 215px;
      max-width: 195px;
  }
  /* .template_dropdown_main button {
      margin-right: 5px;
      margin-top: 0px !important;
  } */
  /* .local_recepients_card .local_recepients_card_right button,
  .local_recepients_card .local_recepients_card_left p {
      font-size: 12.5px;
  } */
  .select_vendor_list_table .template_table_first_row,
  .select_vendor_list_table .template_table_second_row,
  .select_vendor_list_table .template_table_third_row {
      position: relative !important;
      background-color: transparent;
  }
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3),
  .select_vendor_list_table .template_table_second_row,
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(1),
  .select_vendor_list_table .MuiTableBody-root tr td:nth-of-type(2) {
      left: auto;
  }
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3),
  .select_vendor_list_table .template_table_second_row,
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(2),
  .select_vendor_list_table .template_table_third_row {
      min-width: 150px;
  }
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(2),
  .select_vendor_list_table .MuiTableHead-root tr th:nth-of-type(3) {
      left: unset;
  }
}
@media (min-width: 220px) and (max-width: 700px) {
  .card_top_bg
  {
    padding: 0 5px;
  }
  .small_heading,
  .listing_item p
  {
    padding-left: 10px !important;
  }
}

