/* .app-header{
  background: #fff;
  min-height: 60px;
  width: 100%;
  border: solid 1px red;  
} */
.applogo {
    padding-right: 10px;
    border-right: solid 1px var(--bluegrey-500);
  }
  /* .app-toolbar {
    position: sticky;
    top: var(--headerHeight);
    display: flex;
    align-items: center;
    padding: 16px;
    width: 100%;
    z-index: 100;
    background-color: var(--grey-100);
  } */
  /* '.appbar-right .MuiButtonBase-root':{
    color: theme.palette.grey[600],
  }, */
  .use-profile {
    display: flex;
    align-items: center;
    border: solid 1px var(--bluegrey-500);
    border-radius: 4px;
    padding: 0;
    margin: 4px 0;
  }
  .use-profile a{
    display: flex;
    align-items: center;
    padding: 4px 6px;
    text-decoration: none;
  }
  .use-profile .company-logo {
    max-width: 70px;
    height: auto;
    margin-right: 10px;
    display: flex;
  }
  .use-profile .company-logo img {
    max-width: 100%;
    width: 70px;
    height: 38px;
    object-fit: contain;
  }
  