
.template_edit_menu .MuiPaper-rounded
{
    background: var(--thm-white);
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EEF1F6;
    border-radius: 5px;
    padding: 5px;
}
.template_edit_menu li.MuiMenuItem-root
{
    font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
    text-align: left;
    letter-spacing: 0.42px;
    color: var(--text-color);
}
.template_edit_menu li.MuiMenuItem-root:hover
{
    background: var(--mainbg-gray)
}
