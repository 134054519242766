
.card_content .MuiIconButton-sizeLarge
{
  padding: 10px 5px;
}
.card_content .MuiIconButton-sizeLarge svg
{
  width: calc(25px + (25 - 23) * ((100vw - 300px) / (1920 - 300)));

}
.page-header {
  
  width: 100%;
  padding: 9px 22px;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 999;  
}
.card_content .browse_item_btn {
  margin-left: 5px;
}
.card_content .browse_item_btn svg
{
  color: var(--thm-bg) !important;
}
.main_category_search .MuiOutlinedInput-root {
  height: 38px;
  background-color: var(--color-white);
  padding-right: 5px;
} 
.main_category_search .MuiOutlinedInput-root input {  
  padding: 7.5px 5px 7.5px 0;
}
.select_category .MuiOutlinedInput-root {
  border-radius: 50px;
}
.main_category_search .MuiOutlinedInput-root button svg{
 width: 18px;
 height: 18px;
}
.main_category_search .MuiOutlinedInput-root img {
  width: calc(19px + (20 - 19) * ((100vw - 300px) / (1920 - 300)));
}
/* .main_input_outline {
  max-width: 325px;
} */
/* #main_input_outline .MuiChip-root{
  border-radius: 50px;
} */
.page_heading_small {
  font-size: calc(14px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
  line-height: 18px !important;
  letter-spacing: 0px;
  color: var(--thm-bg);
}
/* .main_category_bottom {
  background-color: var(--bg-color);
  padding: 4px 8px !important;
} */
/* .main_category_bottom button svg {
  font-size: calc(18px + (16 - 15) * ((100vw - 300px) / (1920 - 300)));
  margin-right: 5px;
} */
/* .main_category_bottom button svg,
.main_category_bottom button {
  color: var(--thm-gray) !important;
  font-weight: 600;
  font-size: calc(11px + (14 - 12) * ((100vw - 300px) / (1920 - 300)));
} */
.category_dropdownlist span.MuiListItemText-primary {
  /* font-size: calc(14.5px + (15 - 14) * ((100vw - 300px) / (1920 - 300))); */
  margin-left: -12px;
}

.cart-section.template_border {
  border-bottom: 0px !important;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0 !important;
}
.cart-section.template_border .MuiCardContent-root {
  padding-bottom: 16px !important;
}
button.check_tooglebtn svg {
  color: var(--thm-bg) !important;
}
.account_template_border {  
  border-top: 0px !important;
  border-radius: 0px 0px 5px 5px !important;
}
.account_template_border .MuiCardContent-root {
  padding: 0;
  padding-bottom: 60px !important;
}


.main_category_search .MuiOutlinedInput-root.Mui-focused fieldset
{
  border-color:var(--thm-bg);

}
.enter_category_input fieldset {
  border-radius: 5px !important;
}
.MuiTypography-gutterBottom {
  font-size: calc(12.8px + (15 - 12.8) * ((100vw - 300px) / (1920 - 300))) !important;
}

.breadcrumb_headingtext > span {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}
.category_vendor_items {
  padding: 0 10px;
}
.empty_template_div {
  height: 400px;
}
.itemize_vendor_card .MuiDataGrid-root,
.category_vendor_items .MuiDataGrid-root {
  padding: 0 5px;
}
.itemize_vendor_card .MuiDataGrid-virtualScrollerContent,
.category_vendor_items .MuiDataGrid-virtualScrollerContent {
  overflow: visible !important;  
}
.itemize_vendor_card .MuiDataGrid-columnsContainer,
.category_vendor_items .MuiDataGrid-columnsContainer{
 border-bottom: 0 !important;
}
/* .itemize_vendor_card .MuiDataGrid-row,
.category_vendor_items .MuiDataGrid-row {
  background: var(--color-white);  
  margin-bottom: 8px;
  border-radius: 3px;  
  width: 99% !important;
} */
/* .itemize_vendor_card .MuiDataGrid-row:hover,
.category_vendor_items .MuiDataGrid-row:hover
{
  background-color: var(--color-white) !important; 
} */
/* .itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"]:first-child,
.category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]:first-child{
  border-left: 1px solid var(--bluegrey-500);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;  
} */
/* .itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"]:last-child,
.category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]:last-child{
  border-right: 1px solid var(--bluegrey-500);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;  
} */
/* .itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"],
.category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]{
  border-top: 1px solid var(--bluegrey-500);
  border-bottom: 1px solid var(--bluegrey-500);
} */
.breadcrumb_headingtext span h5 {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.9px;
  color: #303e67;
}

.breadcrumb_headingtext span span {
  text-align: left;
  margin: 0 5px;
  font-size: 18px;
  letter-spacing: 0.9px;
  color: #303e67;
  justify-content: space-between;
  display: contents;
}
.breadcrumb_active_link {
  margin: 0 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.9px;
  color: var(--thm-bg);
  line-height: normal !important;
}

/* .card_inner_content {
  min-height: 400px;
  background: var(--main-thm-bg);
  border-bottom: 1px solid var(--bluegrey-500);
} */
/* .card_left_content {
  min-height: 110px;
  border-right: 1px solid var(--bluegrey-500);
} */

/* .card_right_content {
  min-height: 110px;
  border-right: 0px solid var(--bluegrey-500);
} */

.img_icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
/* .listing_item p {
  text-align: left;
  font-size: 14px;
  font-size: calc(13.5px + (15 - 14) * ((100vw - 300px) / (1920 - 300))) !important;
  letter-spacing: 0.7px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
  text-transform: capitalize;
} */
/* .listing_item {
  padding: 10px;
  background: var(--thm-var(--color-white));
  height: 45px;
  border-bottom: 1.5px solid var(--bluegrey-500);
} */
.search_list_main_datagrid {  
  height: 270px !important;  
  background: white;
}

.model_dropdwon_custom {
  margin-top: 25px !important;
}

/*Main Page CSS :: START*/


.checkbox_blue {
  padding: unset !important;
}

/*Local Recipients CSS :: START*/
/* .remove_btn {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: var(--thm-red);
  text-transform: uppercase;
}
.remove_btn svg,
.remove_btn span {
  color: var(--thm-red) !important;
  font-size: 13px;
  font-weight: 600;
} */

/* .checkbox_label_text {
  text-align: left !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  letter-spacing: 0.7px !important;
  color: var(--text-color) !important;  border: solid 1px red;
} */
/* .template_local_recipients .local_template_textfield input {
  border-radius: 4px;
  text-align: left;  
  letter-spacing: 0.15px;
  color: var(--text-small-color);
  min-height: 15px;     border: solid 1px red !important;
} */
.template_local_recipients,
.template_international_recipients,
.template_rfqstatic
{
  margin-top: 25px;
}
/* .rfq_template_Date_picker fieldset,
.template-card .template_Date_picker input,
.international_textarea fieldset,
.template_Date_picker.template_textbox fieldset,
.template_local_recipients .local_template_textarea fieldset,
.template_dropdown .MuiOutlinedInput-notchedOutline,
.template_local_recipients .local_template_textfield .MuiOutlinedInput-notchedOutline {
  border-color: var(--bluegrey-500) !important;
}
.template_textbox .Mui-focused input.Mui-error,
.template_Date_picker.template_textbox .Mui-error fieldset,
.template-card .template_Date_picker input.Mui-error,
.template_local_recipients .local_template_textarea .MuiOutlinedInput-root.Mui-error fieldset,
.template_dropdown.Mui-error .MuiOutlinedInput-notchedOutline,
.template_local_recipients .local_template_textfield.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--thm-red) !important;
}
.rfq_template_Date_picker .Mui-focused fieldset,
.international_textarea .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.international_textarea label.Mui-focused + fieldset.MuiOutlinedInput-notchedOutline,
.template_dropdown.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--thm-bg) !important;
} */

.local_recepients_card .MuiCardContent-root {
  padding: 15px 0 !important;
}

/* .local_recepients_card_left.template_tile p,
.local_recepients_card .local_recepients_card_left p {
  padding-left: 10px;
  text-align: left;
  font-size: calc(14px + (15 - 13.5) * ((100vw - 300px) / (1920 - 300)));
  line-height: 22px;
  letter-spacing: 0.7px;
  color: var(--text-small-color);
  text-transform: uppercase;
  font-weight: 500; border: solid 1px red;
} */
.template_rfqstatic .local_recepients_cards,
.template_international_recipients .local_recepients_cards,
.template_local_recipients .local_recepients_cards {
  padding: 0px 10px 20px 10px; 
}
.button_attachment_text {
  text-align: left;
  font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300))) !important;
  font-weight: 500 !important;
  letter-spacing: 0.7px !important;
  color: var(--text-small-color);
  margin-bottom: 5px !important;
}
.rfq_form_right .rfe_form_btn:hover {
  border: 1px solid rgba(63, 81, 181, 0.5) !important;
}
/* .local_recepients_card .local_recepients_card_right button {
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  line-height: 17px;
  font-weight: 900;
  letter-spacing: 0.42px;
  color: var(--thm-bg);
  margin-right: 10px;
  white-space: nowrap;
} */

.local_recepients_card .local_recepients_card_content {
  padding: 15px 20px;
}
/*Local Recipients CSS :: END*/

/*RFQ FORM CSS :: START*/

.rfq_form_content .MuiCardContent-root {
  padding: 0;
}
.add_reminder_btn {
  background-color: var(--black-textcolor) !important;
  border-radius: 3px !important;
  min-height: 45px;
  margin-right: 15px !important;
}
.add_reminder_btn span svg,
.add_reminder_btn span {
  color: var(--color-white) !important;
}
.reminder_date_btn {
  background-color: var(--thm-gray-bg) !important;
  border-radius: 3px !important;
  min-height: 45px;
  color: var(--black-textcolor) !important;
  font-weight: 600;
}
.reminder_date_btn span svg,
.reminder_date_btn span {
  color: var(--black-textcolor) !important;
}

/*RFQ FORM CSS :: END*/



/*Modal Popup Search CSS :: START*/
.search_popup_btn {
  background-color: transparent !important;
  padding: 5px !important;
}
.search_popup_btn span {
  font-size: 13px;
  color: var(--text-color) !important;
}
.search_popup_btn svg {
  color: var(--text-color) !important;
}
.search_popup_btn.add_product span,
.search_popup_btn.add_product svg {
  color: var(--thm-red) !important;
  font-weight: 700;
}

/*Modal Popup Search CSS :: END*/


/*Main Page CSS :: END*/

/*Select Template Notes Dialog CSS :: END*/

@media screen and (max-width: 1920px) {
  /* .search_list_main_datagrid {
      top: 11%;
      left: 1%;
  } */
}
@media screen and (max-width: 1680px) {
  /* .search_list_main_datagrid {
      top: 12% !important;
      left: 1.3%;
  } */
}
@media screen and (max-width: 1440px) {
  /* .search_list_main_datagrid {
      top: 13% !important;
      left: 1.5%;
  } */
}

@media only screen and (max-width: 991.5px) {
  /* .search_list_main_datagrid {
      width: calc(100% - 20%) !important;
  } */
    
  .add_reminder_btn,
  .reminder_date_btn {
      margin-top: 10px !important;
  }
  .main_category_search .MuiOutlinedInput-root {
      margin-top: 10px;
  }
  .card_content .browse_item_btn {
      margin-left: 0px;
      margin-top: 5px;
  }
  /* .search_list_main_datagrid {
      width: calc(100% - 10%) !important;
      top: 15% !important;
      left: 2%;
  } */
  .search_backdrop_input {
      width: 90vw !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* .search_list_main_datagrid {
      width: calc(100% - 20%) !important;
  } */
}
@media (min-width: 768px) and (max-width: 991.5px) {
  .main_input_outline {
      /* margin-top: 10px; */
      width: 380px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 800px) {
  /* .search_list_main_datagrid {
      width: calc(100% - 20px) !important;
      min-height: 270px !important;
  } */
}
@media (min-width: 280px) and (max-width: 700px) {
  /* .search_list_main_datagrid {
      top: 12% !important;
      left: 6%;
  } */
  .main_input_outline {
      width: 100%;
      margin-top: 10px;
  }
}
