:root{
    --color-white: #FFFFFF;
    --primary: #096bef;
    --secondary: #1f2b3a;
    --success: #5bceae;
    --info: #00c3d9;
    --warning: #f4bd0e;
    --error: #ff5547;
    --bg-body: #EBEEF2;    
    --text-primary: #5a6778;    
    --grey-0:   #FFFFFF;
    --grey-50:  #F6F8FB;  
    --grey-100: #ebeef2 ;
    --grey-200: #e5e9f2;
    --grey-300: #dbdfea;
    --grey-400: #b7c2d0; 
    --grey-500: #8091a7;
    --grey-600: #3c4d62;
    --grey-700: #344357;
    --grey-800: #2b3748;
    --grey-900: #1f2b3a; 
    --bluegrey-0:   #FFFFFF;  
    --bluegrey-100: #F2FBFC;
    --bluegrey-200: #E5F5FA;
    --bluegrey-300: #D2E9F1;
    --bluegrey-400: #C0D8E4; 
    --bluegrey-500: #a6c1d3;
    --bluegrey-600: #7999B5;
    --bluegrey-700: #537497;
    --bluegrey-800: #34527A;
    --bluegrey-900: #1F3965; 
}

.item-search-wrap{
    display: flex;    
    align-items: center;
}
.item-search-wrap .browse-item-btn{
    margin-left: 16px;    
}

/* .card_inner_content {
    min-height: 400px;
    background: var(--grey-50);    
  } */
/* .card_left_content {
    min-height: 110px;
    border-right: 1px solid var(--bluegrey-500);
} */
/* .card_right_content {
    min-height: 110px;    
} */

@media only screen and (max-width: 900px) {
    .item-search-wrap .browse-item-btn{      
        margin-left: auto;
    }
    .item-search-wrap button .MuiButton-startIcon{
        margin-right: 0;
        margin-left: 0;
    }
  }
