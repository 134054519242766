* {
  margin: 0;
  padding: 0;  
}

/* 2) Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: normal;
  color: #000;   
}

h1 {
  font-size: 2.5em;
  line-height: 1.25em;
}

h2 {
  font-size: 1.6667em;
  line-height: 1.25em;
}

h3 {
  font-size: 1.5em;
  line-height: 1.2222em;
}

h4 {
  font-size: 1.3333em;
  line-height: 1.25em;
}

h5 {
  font-size: 1.1666em;
  line-height: 1.1428em;
}

h6 {
  font-size: 1em;
}

:root {
  --main-thm-bg:#f5f6fa;
  --mainbg-color:#ebeef2;
  --mainbg-gray:#F6F8FB;
  --thm-white: #ffffff;
  --thm-light-blue: #38485f;
  --unnamed-color-00c3d9: #00c3d9;
  --unnamed-color-1c1e56: #1c1e56;
  --thm-green: #00d994;
  --thm-red-light: #df3868;
  --thm-bg: #096bef;
  --thm-gray: #64748b;
  --thm-gray-bg:#f1f5f9;
  --second-green: #5bceae;
  --border-outline : #A6C1D3;
  --outline-color: #5A6778;
  --text-color:#5a6778;
  --bg-color:#E8ECEF;
  --black-textcolor:#1F2B3A;
  --text-small-color:#3c4d62;
  --thm-red:#FF5547;
  --small-text-ceolor:#8094AE;
  --backdrop-color:#364A63;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}
/* 
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
::-webkit-scrollbar-thumb:hover {
  background: #c3c3c3;
} */

/* * {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #f1f1f1;
} */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 0px;  
  border: 0 solid #ffffff;
} */

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

.w-space-no {
  white-space: nowrap;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
/* svg.MuiSvgIcon-root {
  color: var(--border-outline);
} */
span.Mui-checked svg.MuiSvgIcon-root {
  /* color: var(--thm-bg); */
}
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: var(--thm-white) !important;
}
.MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root:nth-child(2){
  display: none;
}