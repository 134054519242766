.FooterBar {
    background: var(--grey-0);
    border-top: solid 1px var(--bluegrey-500);
    padding: 6px 16px;
    position: fixed;
    width: 100%;
    bottom: 0;
    min-height: 32px;
    right: 0;
    z-index: 999;
}

@media (max-width:600px) {
    .FooterBar {
        padding: 6px;
    }
    .FooterBar .MuiTypography-root {
        font-size: 12px;
    }
}